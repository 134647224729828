import Vue from 'vue'
import Vuex from 'vuex'
import login from '@/store/login'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        appIsLoading: false,
    },
    getters: {},
    mutations: {
        setAppIsLoading (state): void {
            state.appIsLoading = true
        },
        setAppIsNotLoading (state): void {
            state.appIsLoading = false
        },
    },
    actions: {},
    modules: {
        login,
    },
})
