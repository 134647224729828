









import Vue from 'vue'
import Navbar from '@/components/layout/Navbar.vue'

export default Vue.extend({
    components: {
        Navbar,
    },
})
