import Vue from 'vue'
import { BootstrapVue, IconsPlugin, NavbarPlugin, FormPlugin, TablePlugin, BreadcrumbPlugin } from 'bootstrap-vue'

// // Import Bootstrap an BootstrapVue CSS files (order is important)
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(NavbarPlugin)
Vue.use(FormPlugin)
Vue.use(TablePlugin)
Vue.use(BreadcrumbPlugin)
