import axios, { AxiosInstance } from 'axios'
import store from '@/store'
import router from '@/router'

export const authorizedRequest = (): AxiosInstance => {
    const client = axios.create({
        baseURL: process.env.VUE_APP_API_URL,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + store.getters['login/accessToken'],
        },
    })

    client.interceptors.response.use(
        (response) => {
            successInterceptor()
            return response
        },
        (error) => {
            failInterceptor(error)
            return Promise.reject(error)
        },
    )

    return client
}

export const nonAuthorizedRequest = (): AxiosInstance => {
    const client = axios.create({
        baseURL: process.env.VUE_APP_API_URL,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    })

    client.interceptors.response.use(
        (response) => {
            successInterceptor()
            return response
        },
        (error) => {
            failInterceptor(error)
            return Promise.reject(error)
        },
    )

    return client
}

function successInterceptor() {
    // store.commit('errors/setServerRequestFailedState', false)
    // store.commit('errors/clearValidationErrors')
}

function failInterceptor(error: any) {
    store.commit('setAppIsNotLoading')

    // if (!error.response) {
    //     store.commit('errors/setServerRequestFailedState', true)
    //     return
    // }

    const isAuthenticatedRequest = error.response.config && error.response.config.headers.Authorization !== undefined

    switch (error.response.status) {
        case 401:
            if (isAuthenticatedRequest) {
                store.dispatch('login/refreshIsLoggedState')
                    .then(() => {
                        error.request.repeat()
                    })
            }
            break
        case 403:
            router.push({ name: 'dashboard' })
            break
        case 404:
            router.push('/404')
            break
        case 422:
            // store.commit('errors/setValidationMessage', error.response.data.message || error.response.data.data.message)
            // store.commit('errors/setValidationErrors', error.response.data.errors || error.response.data.data.errors)
            break
        case 503:
            router.push({ name: 'appInMaintenance' })
            break
        default:
            // store.commit('errors/setServerRequestFailedState', true)
            break
    }
}
