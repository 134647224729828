export const API_ROUTES = {
    AUTH: {
        LOGIN: '/api/auth/login',
        REFRESH_TOKEN: '/api/auth/refresh',
    },
    PORTFOLIOS: {
        GET: '/api/portfolios',
        GET_SINGLE: '/api/portfolios/',
        ADD: '/api/portfolios',
        UPDATE: '/api/portfolios/',
        DELETE: '/api/portfolios/',

        GET_SINGLE_POSITION: '/api/portfolios/positions/',
        ADD_POSITION: '/api/portfolios/positions',
        UPDATE_POSITION: '/api/portfolios/positions/',
        DELETE_POSITION: '/api/portfolios/positions/',
        CLOSE_POSITION: '/api/portfolios/positions/close/',

        ADD_TRANSACTION: '/api/portfolios/transactions',
        UPDATE_TRANSACTION: '/api/portfolios/transactions/',
        DELETE_TRANSACTION: '/api/portfolios/transactions/',
    },
    CURRENCIES: {
        SEARCH: '/api/currencies',
    },
}
