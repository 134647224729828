import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        meta: {
            onlyForGuests: true,
        },
    },
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "portfolios" */ '../views/Portfolios.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/portfolio/:portfolioId',
        name: 'portfolios.single',
        component: () => import(/* webpackChunkName: "singlePortfolio" */ '../views/SinglePortfolio.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/portfolio/positions/:positionId',
        name: 'portfolios.positions.single',
        component: () => import(/* webpackChunkName: "singlePosition" */ '../views/SinglePosition.vue'),
        meta: {
            requiresAuth: true,
        },
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

export default router

router.beforeEach((to, from, next) => {
    store.commit('setAppIsNotLoading')
    authChecks(to, from, next)
})

function authChecks (to: any, from: any, next: any): void {
    store.dispatch('login/refreshIsLoggedState')
    if (to.matched.some((record: any) => record.meta.requiresAuth) && !store.getters['login/isLogged']) {
        next({
            name: 'login',
        })
    } else if (to.matched.some((record: any) => record.meta.onlyForGuests) && store.getters['login/isLogged']) {
        next({
            name: 'home',
        })
    } else {
        next()
    }
}
