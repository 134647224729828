import { authorizedRequest, nonAuthorizedRequest } from '@/api/api'
import { API_ROUTES } from '@/api/apiRoutes'
import { TokenStorage } from '@/utils/auth/TokenStorage'
import moment from 'moment'

export const CURRENT_USER_LOCAL_STORAGE_KEY = 'current_user'
export const PERMISSIONS_LOCAL_STORAGE_KEY = 'permissions'

const tokenStorage = new TokenStorage()

export default {
    namespaced: true,
    state: {
        isLogged: false,
        loginFailed: false,
        accessToken: null,
        expiresAt: null,
        lastVisitedPage: null,
        currentUser: null,
        permissions: [],
    },
    getters: {
        isLogged(state: any): boolean {
            return state.isLogged
        },
        accessToken(state: any): string | null {
            return state.accessToken
        },
        expiresAt(state: any): string | null {
            return state.expiresAt
        },
    },
    mutations: {
        // eslint-disable-next-line camelcase
        saveTokensToStorage(state: any, payload: { expires_in: number, access_token: string }): void {
            tokenStorage.saveToken(payload.access_token, payload.expires_in)

            state.accessToken = tokenStorage.getToken()
            state.expiresAt = tokenStorage.getExpiresAt()
        },
        saveTokensToState(state: any, payload: { token: number, expiresAt: string }): void {
            state.accessToken = payload.token
            state.expiresAt = payload.expiresAt
        },
        setLoginError(state: any, value: boolean): void {
            state.loginFailed = value
        },
        clearStateAndLocalStorage(state: any): void {
            state.accessToken = null
            state.expiresAt = null
            state.permissions = []

            tokenStorage.clearToken()
            localStorage.removeItem(CURRENT_USER_LOCAL_STORAGE_KEY)
            localStorage.removeItem(PERMISSIONS_LOCAL_STORAGE_KEY)
        },
        setIsLogged(state: any, isLogged: boolean): void {
            state.isLogged = isLogged
        },
        setPermissions(state: any, permissions: Array<string>): void {
            state.permissions = permissions
            localStorage.setItem(PERMISSIONS_LOCAL_STORAGE_KEY, JSON.stringify(permissions))
        },
    },
    actions: {
        logIn(store: any, payload: { email: string, password: string }): Promise<void> {
            store.commit('setAppIsLoading', true, { root: true })
            return nonAuthorizedRequest()
                .post(
                    API_ROUTES.AUTH.LOGIN,
                    {
                        email: payload.email,
                        password: payload.password,
                    },
                )
                .then((response) => {
                    store.commit('saveTokensToStorage', response.data)
                    store.commit('setIsLogged', true)
                    store.commit('setAppIsNotLoading', false, { root: true })
                })
                .catch((e) => {
                    store.commit('setLoginError', true)
                    throw e
                })
        },
        refreshIsLoggedState(store: any): void {
            const token = tokenStorage.getToken()
            const expiresAt = tokenStorage.getExpiresAt()

            if (token && expiresAt) {
                store.commit('saveTokensToState', {
                    token: token,
                    expiresAt: expiresAt,
                })
                store.commit('setIsLogged', true)

                if (moment(expiresAt) <= moment().subtract(5, 'minutes')) {
                    store.dispatch('refreshToken')
                }
            } else {
                store.commit('setIsLogged', false)
                store.commit('clearStateAndLocalStorage')
            }
        },
        refreshToken(store: any) {
            return authorizedRequest().post(API_ROUTES.AUTH.REFRESH_TOKEN)
                .then((response) => {
                    store.commit('saveTokensToStorage', response.data)
                    store.commit('setIsLogged', true)
                })
                .catch((e) => {
                    console.error(e)
                    store.commit('setIsLogged', false)
                    store.commit('clearStateAndLocalStorage')
                })
        },
    },
}
