import moment from 'moment'

export const ACCESS_TOKEN_KEY = 'accessToken'
export const EXPIRES_AT_KEY = 'expiresAt'

export class TokenStorage {
    public getToken(): string | null {
        return localStorage.getItem(ACCESS_TOKEN_KEY) || null
    }

    public getExpiresAt(): string | null {
        return localStorage.getItem(EXPIRES_AT_KEY) || null
    }

    public saveToken(token: string, expiresIn: number): void {
        localStorage.setItem(ACCESS_TOKEN_KEY, token)
        localStorage.setItem(EXPIRES_AT_KEY, this.convertExpiresInToDateString(expiresIn))
    }

    public clearToken(): void {
        localStorage.removeItem(EXPIRES_AT_KEY)
        localStorage.removeItem(ACCESS_TOKEN_KEY)
    }

    private convertExpiresInToDateString(expiresIn: number): string {
        return moment().add(expiresIn, 'seconds').format()
    }
}
